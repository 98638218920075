import { ReactElement } from 'react';
import Image from 'next/image';
import PageWidth from 'styles/components/PageWidth';
import data from './data';

const WhatMakesUsSpecial = (): ReactElement => (
  <div className="w-full bg-main text-white px-7 py-6">
    <PageWidth>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        {data.map(({ icon, text }) => (
          <div className="flex flex-col text-center" key={text}>
            <div className="rounded-full bg-white flex align-middle justify-center h-32 w-32 p-6 mb-5 mx-auto">
              <Image width={80} height={80} src={icon} alt={text} />
            </div>
            {text}
          </div>
        ))}
      </div>
    </PageWidth>
  </div>
);

export default WhatMakesUsSpecial;
