const data = [
  {
    icon:
      'https://cdn.shopify.com/s/files/1/0019/7060/2050/files/craft-tool-scissors-pencil-glue-stationery_100x.png',
    text:
      'Crafty Party Box provides an easy way for you to bring crafting to  parties or just spending some quality time with your little ones. Inject some screen free fun to your home and bring back the simplicity and fun of hands-on activities.',
  },
  {
    icon:
      'https://cdn.shopify.com/s/files/1/0019/7060/2050/files/gift-present-star-box-surprise_100x.png',
    text:
      'Each box is created and put together with care to ensure the best quality.',
  },
  {
    icon:
      'https://cdn.shopify.com/s/files/1/0019/7060/2050/files/dancing-exercise-celebration-happy-party_100x.png',
    text:
      'We know how hard and time consuming it can be to organize a party for your little ones. So let us take out the stress and free up your time and organise everything you need for the main activity, which can also be used as a party favour to take home. One less thing to worry about!',
  },
];

export default data;
